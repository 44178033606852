:root {
  --primaryColor: #D2D7F1;
  --secondaryColor: #30a49aff;
  --tertiaryColor: #D2D7F1;
  --lightWhite:#EDF2FF;
  --dark: #0B073D;
  --gray: #27374D;
  --black: #000000;
}

@font-face {
  font-family: 'candara-bold-2';
  src: url('./fonts/candara-bold-2.ttf') format('truetype');
  font-style: normal;
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Pour que le conteneur prenne la pleine hauteur de l'écran */
}

.logo-image {
  width: 250px; /* Ajustez selon la taille souhaitée */
  height: auto; /* Pour que l'image garde ses proportions */
}

.logo-color {
  color: var(--secondaryColor);
}

.screen-image {
  width: 220px; /* Ajustez selon la taille souhaitée */
  height: auto; /* Pour que l'image garde ses proportions */
  transition: transform 0.5s ease-in-out;
}
.qrcode {
  width: 150px; /* Ajustez selon la taille souhaitée */
  height: auto; /* Pour que l'image garde ses proportions */
  margin-bottom: 10px;
  margin-top: 10px;
}

.screen-image:hover {
  transform: scale(1.2);
}


.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Prend la hauteur totale de la fenêtre */
  
}

header {
  position: sticky;
  top: 0;
  padding: 10px 50px 10px 50px;
  background-color: #222;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 1000;
  font-size: 20px;
  border-bottom: 1px solid var(--dark);
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 500;
  align-items: center;
}

.menu-item {
  text-decoration: none; /* Retire le soulignement */
  color: var(--lightWhite);
  padding: 20px 50px;
  border-bottom: 1px solid var(--lightWhite);
}

.menu-item:hover {
  color: var(--secondaryColor);
  border-bottom: 1px solid var(--secondaryColor);
}

body {
  flex: 1; 
  overflow-y: auto; 
  background-color: var(--secondaryColor);
  font-family: 'candara-bold-2';
  color: var(--dark);
}

.page {
  height: 100;
  padding: 0px 10%;
  align-content: center;
  align-items: center;
  background-color: var(--lightWhite);
}

.page-content {
  width: 100%;
  min-height: 400px;
  padding: 10px 0;
  background-color: var(--lightWhite);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}

.page-content-row {
  padding: 10px 80px;
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

p {
  font-size: 18;
   line-height: 1.8;
}

.page-content-row > div {
  width: 45%;
  align-content: center;
}

.hide-on-mobile {
  display: block; /* Visible par défaut */
}

.show-on-mobile {
  display: none;
}

@media (max-width: 1080px) {

  .hide-on-mobile {
    display: none; /* Masqué sur les petits écrans */
  }

  .show-on-mobile {
    display: block;
  }

  .page {
    height: 100;
    padding: 0px;
    align-content: center;
    align-items: center;
    background-color: white;
  }

  .page-content {
    width: 100%;
    padding: 25px 0;
    background-color: var(--lightWhite);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
    
  }
  
  .page-content-row {
    padding: 0px;
    display: flex;
    align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
  }

  .page-content-row > div {
    width: 85%;
    text-align: center;
  }

  .screen-image:hover {
    transform: scale(1.05);
  }
}

body {
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
}

.mobile-menu {
  display: block;
}

@media (min-width: 769px) {
  .mobile-menu {
    display: none;
   
  }
}

/* Personnalisation du menu burger */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 15px;
  top: 15px;
}

.bm-burger-bars {
  background: var(--secondaryColor);
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  margin-top: 2.5em;
  font-size: 1.15em;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  color: var(--lightWhite);
  padding: 10px 0;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.download-button {
  color: var(--secondaryColor);
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  
}

.playstore {
  width: 150px; /* Ajustez selon la taille souhaitée */
  height: auto; /* Pour que l'image garde ses proportions */
  margin-top: 0px;
}

.download-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
  background-color: var(--secondaryColor);
}